import { Link } from 'react-router-dom';

function Vacancies() {
  return (
    <section className="vacancies-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Вакансии
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2">Наши вакансии</h2>
        <h3 className=" pt-xxl-2">Оператор колл-центра</h3>
        <p>Обязанности:</p>
        <ul>
          <li>Прием входящих звонков</li>
          <li>Консультирование клиентов по продуктам банка</li>
          <li>Продажа продуктов и услуг банка </li>
        </ul>
        <p>Требования:</p>
        <ul>
          <li>Уверенное владение ПК</li>
          <li>Опыт работы от 1 года </li>
        </ul>
        <p>Условия:</p>
        <ul>
          <li>
            График работы 2/2 (дневные и ночные смены) с 08.00 до 20.00 и с
            20.00 до 08.00
          </li>
          <li>Официальное трудоустройство</li>
          <li>Выплаты заработной платы 2 раза в месяц без задержек</li>
          <li>
            Корпоративное бесплатное обучение. Возможность карьерного роста
          </li>
        </ul>
        <h3 className="pt-4 pt-xxl-4">Помощник руководителя отдела продаж</h3>
        <p>Обязанности:</p>
        <ul>
          <li>Контроль качества исполнения работ отдела</li>
          <li>Распределение задач между сотрудниками отдела</li>
          <li>Ведение отчетности</li>
        </ul>
        <p>Требования:</p>
        <ul>
          <li>Уверенное владение ПК</li>
          <li>Опыт работы в сфере продаж от 1 года</li>
          <li>Опыт работы на руководящей должности приветствуется</li>
        </ul>
        <p>Условия:</p>
        <ul>
          <li>График работы 5/2 с 08.00 до 17.00</li>
          <li>Официальное трудоустройство</li>
          <li>Выплаты заработной платы 2 раза в месяц без задержек</li>
          <li>
            Корпоративное бесплатное обучение. Возможность карьерного роста
          </li>
        </ul>
        <h3 className="pt-4 pt-xxl-4">
          Почта для связи{' '}
          <a className="email-link" href="mailto:job@zo-tel.com">
            job@zo-tel.com
          </a>
        </h3>
      </div>
    </section>
  );
}

export default Vacancies;
