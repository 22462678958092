import { useState } from 'react';
import logo from '../../assets/img/logo.svg';
import girl from '../../assets/img/main-pic.png';
import Fade from 'react-bootstrap/Fade';


function Main() {
  const [open, setOpen] = useState(false);

  return (
    <section className="main-container mx-auto text-center py-2 py-lg-3 py-xl-4 py-xxl-5">
      <div className="container text-center accordion">
        <div style={{ maxWidth: 276 }} className="mx-auto">
          <img src={girl} alt="Support" className="my-4" />
        </div>

        <div style={{ maxWidth: 415 }} className="mx-auto">
          <img src={logo} alt="TOO ZOTEL" className="my-4" />
        </div>

        <h3 className="display-5 text-center pt-xxl-2">
          Выбирая TOO ZOTEL — вы выбираете качество!
        </h3>

        <div className="fs-3 mt-4 mb-2">
          10 лет предоставления услуг аутсорсинг контакт-центра!
        </div>

        <button
          className={`accordion-button mx-auto ${open ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        ></button>

        <Fade in={open}>
          <div
            id="example-collapse-text"
            className="mx-auto fs-4"
            style={{ maxWidth: 1150, display: open ? 'block' : 'none' }}
          >
            Аутсорсинг контакт-центров — это услуга, которая позволяет компаниям
            сократить расходы на содержание собственного контакт-центра и при
            этом повысить качество обслуживания клиентов за счет привлечения
            внешних специалистов с опытом работы и необходимыми навыками.
            Контакт-центр TOO ZOTEL – это совокупность сотрудников, оборудования
            и программного обеспечения, которые обеспечивают своевременное и
            качественное обслуживание клиентов
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Main;
