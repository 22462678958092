import { useState } from 'react';
import operators from '../../assets/img/operators.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function SolutionsOpearots() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Решения</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Операторам связи
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Готовые решения для операторов связи
        </h2>

        <p>
          Все наши решения разработаны на основе многолетнего опыта работы. Мы
          знаем как важно, чтобы решение было максимально гибким и удобным для
          оператора связи. Поэтому мы тщательно продумываем все детали, которые
          могут повлиять на удобство работы оператора.
        </p>
        <div className="row ms-1">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Разработка проекта</li>
              <li className="my-3">Создание и проработка скриптов</li>
              <li className="my-3">Организация горячей линии</li>
              <li className="my-3">
                Организация “умного” распределения входящих вызовов
              </li>
              <li className="my-3">Проведение холодных звонков</li>
              <li className="my-3">
                Оперативная обработка входящих обращений пользователей
              </li>
              <li>Детальная отчетность</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Оставить заявку
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={operators}
            alt="for-operators"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default SolutionsOpearots;
