import logo from '../../assets/img/logo.svg';
import map from '../../assets/img/map.png';

function Map() {
  return (
    <section
      id="coverage"
      className="mx-auto text-center py-2 py-lg-3 py-xl-4 py-xxl-5"
    >
      <div className="container text-center py-5 mt-1 mb-2 mt-sm-2 mb-sm-3 mt-md-3 mb-md-4 my-lg-4 my-xl-5">
        <h2 className="display-5 text-center pt-xxl-2 mb-4">Карта покрытия</h2>
        <div style={{ maxWidth: 415 }} className="mx-auto mb-5">
          <img src={logo} alt="TOO ZOTEL" />
        </div>

        {/* <div style={{ maxWidth: 276 }} className="mx-auto"> */}
        <img src={map} alt="map" className="mt-5" />
        {/* </div> */}
      </div>
    </section>
  );
}

export default Map;
