import { useState } from 'react';
import banking from '../../assets/img/banking.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function SolutionsBanking() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Решения</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Банковская сфера
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Готовые решения для банковской сферы
        </h2>

        <p>
          Мы предлагаем готовые решения, разработанные на основе опыта различных
          банков и международных финансовых институтов. Наши системы не просто
          автоматизируют работу контакт-центра, но и позволяют обеспечить
          высокий уровень качества обслуживания клиентов.
        </p>
        <div className="row ms-1">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Разработка проекта</li>
              <li className="my-3">Создание и проработка скриптов</li>
              <li className="my-3">Организация горячей линии</li>
              <li className="my-3">
                Организация базы данных потенциальных клиентов
              </li>
              <li className="my-3">Проведение холодных звонков</li>
              <li className="my-3">Организация опросов и их обработка</li>
              <li className="my-3">
                Информирование о новых продуктах и специальных предложениях
              </li>
              <li className="my-3">Детальная отчетность</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Оставить заявку
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={banking}
            alt="for-banking"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default SolutionsBanking;
