import { useState } from 'react';
import logo from '../../assets/img/logo-underline.svg';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ModalComponent from './Modal';
import { HashLink } from 'react-router-hash-link';
import NAV_ITEMS from '../../assets/const';

function Navbar() {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <header className="navbar navbar-expand-lg bg-light">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="TOO ZOTEL" />
          </Link>
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse1"
            aria-expanded={open}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <nav
            className={`collapse navbar-collapse ${open ? 'show' : ''}`}
            id="navbarCollapse1"
          >
            <ul className="navbar-nav me-auto">
              {NAV_ITEMS.map((i) => (
                <li className="nav-item active" key={i.title}>
                  {i.hash ? (
                    <HashLink smooth className="nav-link" to={i.hash}>
                      {i.title}
                    </HashLink>
                  ) : (
                    <Link className="nav-link" to={i.link}>
                      {i.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <Button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modalId"
              size="sm"
              onClick={() => setOpenModal(true)}
            >
              Оставить заявку
            </Button>
          </nav>
        </div>
      </header>

      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </>
  );
}

export default Navbar;
