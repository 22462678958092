import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const initialForm = {
  name: '',
  scope: '',
  phone: '',
};

function ModalComponent({ open, setOpen }) {
  const [isLoading, setLoading] = useState(false);
  const [needShowError, showError] = useState(false);
  const [needShowSuccess, showSuccess] = useState(false);

  const [form, setForm] = useState(initialForm);
  const { name, phone, scope } = form;

  const handleChange = (object) => {
    setForm({
      ...form,
      ...object,
    });
  };

  const handleClose = () => {
    showError(false);
    showSuccess(false);
    setForm(initialForm);
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    fetch('https://zo-tel.com/contact', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(form),
    })
      .then(() => {
        showSuccess(true);
        showError(false);
      })
      .catch(() => {
        showError(true);
        showSuccess(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header className="pb-1" closeButton />

      <Modal.Body className="py-1">
        <h3 className="text-center">Оставить заявку</h3>
        <p className="text-center">
          Наш менеджер свяжется с вами в ближайше время
        </p>
        <form onSubmit={handleSubmit}>
          <Form.Control
            className="my-4"
            placeholder="Ваше имя"
            value={name}
            required
            name="name"
            onChange={(e) => handleChange({ name: e.target.value })}
          />
          <Form.Control
            className="my-4"
            placeholder="Сфера вашей деятельности"
            value={scope}
            required
            name="scope"
            onChange={(e) => handleChange({ scope: e.target.value })}
          />
          <PhoneInput
            inputProps={{
              required: true,
            }}
            className="my-4 phone-input-container"
            placeholder="+7 701 1111111"
            onlyCountries={['kz', 'ru']}
            preferredCountries={['kz']}
            value={phone}
            onChange={(value) => handleChange({ phone: value })}
          />

          {needShowError && (
            <Alert variant="danger">
              Произошла ошибка. Пожалуйста, повторите попытку позже
            </Alert>
          )}

          {needShowSuccess && (
            <Alert variant="success">Спасибо. Ваша зявка отправлена</Alert>
          )}

          <div className="text-center">
            <Button
              className="mt-3 mb-5"
              type="submit"
              disabled={isLoading}
              variant="primary"
              style={{ width: '100%' }}
            >
              Отправить
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
