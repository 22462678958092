import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.svg';
import { HashLink } from 'react-router-hash-link';
import NAV_ITEMS from '../../assets/const';

function Footer() {
  return (
    <header className="navbar navbar-expand-lg bg-white px-3 mx-3 my-5">
      <div className="container footer">
        <div style={{ maxWidth: 300, fontSize: 12 }}>
          <div style={{ width: 170 }} className="me-1 mb-4">
            <Link to="/">
              <img src={logo} alt="TOO ZOTEL" />
            </Link>
          </div>
          <address>
            <div className="my-2">
              <strong>ТОО "ZOTEL"</strong>
            </div>

            <div className="my-1">БИН: 221240011149</div>
            <div className="my-1">
              Республика Казахастан, г. Алматы, улица Тулебаева 38/61
            </div>
          </address>
        </div>

        {NAV_ITEMS.map((i) => (
          <div style={{ display: 'flex' }} key={i.title}>
            {i.hash ? (
              <HashLink smooth className="nav-link" to={i.hash}>
                {i.title}
              </HashLink>
            ) : (
              <Link className="nav-link" to={i.link}>
                {i.title}
              </Link>
            )}

            <div className="separate ms-4" />
          </div>
        ))}
        <a
          className="email-link email-link--no-underline"
          href="mailto:sale@zo-tel.com"
        >
          sale@zo-tel.com
        </a>
      </div>
    </header>
  );
}

export default Footer;
