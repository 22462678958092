import { useState } from 'react';
import store from '../../assets/img/store.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function Solutions() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Решения</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Интернет-магазинам
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Готовые решения для интернет-магазинов
        </h2>

        <p>
          Компания TOO ZOTEL предлагает готовые решения для операторов
          контакт-центров и колл-центров по обслуживанию интернет-магазина. Это
          готовые решения, которые мы разрабатывали специально для наших
          клиентов, чтобы они могли максимально быстро и качественно начать
          работу.
        </p>
        <div className="row">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Разработка проекта</li>
              <li className="my-3">Создание и проработка скриптов</li>
              <li className="my-3">Организация горячей линии</li>
              <li className="my-3">Круглосуточный прием звонков</li>
              <li className="my-3">Оперативная обработка заказов</li>
              <li className="my-3">Детальная отчетность</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Оставить заявку
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={store}
            alt="for-store"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default Solutions;
