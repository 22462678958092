import { Link } from 'react-router-dom';

function About() {
  return (
    <section className="about-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              О компании
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2">О компании</h2>

        <address>
          <div className="my-1">
            <strong>ТОО "ZOTEL"</strong>
          </div>

          <div className="my-1">БИН: 221240011149</div>
          <div className="mt-1 mb-5">
            Республика Казахастан, г. Алматы, улица Тулебаева 38/61
          </div>
        </address>

        <div className="row my-4">
          <div className="col-3 text-bold">2012</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Июнь — была основана компания TOO ZOTEL, состоящая на тот момент из
            4х сотрудников
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2013</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Компания активно развивается в сфере услуг по call-центр
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2015</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Компания TOO ZOTEL уходит на аутсорс. Запущена собственная
            разработка воронок и скриптов
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2017</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            TOO ZOTEL переходит в статус контакт-центра. Идет активное развитие
            исходящих звонков и обработки заявок{' '}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2018</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            За плечами сотрудничество с ведущими операторами связи страны.
            Полная модернизация оборудования и увеличение рабочих направлений.
            Карта покрытия теперь охватывает все регионы страны
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2019</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Компания TOO ZOTEL выходит на международный уровень{' '}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2021</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Компания TOO ZOTEL становится действительно большой компанией. Штат
            сотрудников превышает 500 человек
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2022</div>
          <div className="col-9 ">...</div>
        </div>
      </div>
    </section>
  );
}

export default About;
