import { Link } from 'react-router-dom';

function Solutions() {
  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <h2 className="display-5  pt-xxl-2">Готовые решения</h2>

        <p>
          Здесь вы можете ознакомиться с различными решениями, разработанными
          нашей компанией, что позволит вам быстро и легко найти подходящее для
          ваших нужд. Решения, представленные на этой странице, охватывают
          различные сферы деятельности и категории клиентов.
        </p>
        <div className="flex-col-center">
          <Link to="/solutions/for-online-store">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              Для интернет-магазинов
            </div>
          </Link>
          <Link to="/solutions/for-operators">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              Для оперторов связи
            </div>
          </Link>
          <Link to="/solutions/for-banking">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              Для банковской сферы
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
