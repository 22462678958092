const buns = [
  {
    icon: require('../../assets/img/earphones.svg').default,
    title: 'Профессиональная обработка заявок',
    text: 'Каждый сотрудник вовлекается в ваши цели и задачи!',
  },
  {
    icon: require('../../assets/img/rocket.svg').default,
    title: 'Детальная отчетность',
    text: 'Прозрачность в обработке каждой заявки',
  },
  {
    icon: require('../../assets/img/lamp.svg').default,
    title: 'Гибкость',
    text: 'Операторы TOO ZOTEL владеют двумя языками. Легко адаптируются к новым скриптам продаж',
  },
  {
    icon: require('../../assets/img/settings.svg').default,
    title: '24/7',
    text: 'Обработка заявок в любое время',
  },
  {
    icon: require('../../assets/img/clock.svg').default,
    title: 'Безлимит',
    text: 'Нет ограничения на входящие заявки. Гарантия обработки каждой заявки в день получения',
  },
  {
    icon: require('../../assets/img/clock.svg').default,
    title: 'Опыт',
    text: 'Помощь в оптимизации на всех этапах обработки заявки от получения до продажи',
  },
];
function Buns() {
  return (
    <section className="py-2 py-lg-3 py-xl-4 py-xxl-5">
      <div className="container py-5 mt-1 mb-2 mt-sm-2 mb-sm-3 mt-md-3 mb-md-4 my-lg-4 my-xl-5">
        <h2 className="display-5 text-center pt-xxl-2">
          Работая с нами вы получаете:
        </h2>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          <>
            {buns.map((i) => (
              <div className="col" key={i.title}>
                <div className="card border-0 ">
                  <div className="buns-card bg-secondary card-body text-center rounded-5 py-2 py-lg-4 px-0 p-5">
                    <img
                      className="buns-icon mx-auto"
                      src={i.icon}
                      alt={i.title}
                    />
                    <h3 className="mb-0 px-5">{i.title}</h3>
                    <p className="card-text px-5">{i.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>
      </div>
    </section>
  );
}

export default Buns;
