const NAV_ITEMS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Услуги',
    hash: '/#service',
  },
  {
    title: 'Цены',
    hash: '/#prices',
  },
  {
    title: 'Готовые решения',
    link: 'solutions',
  },
  {
    title: 'Карта покрытия',
    hash: '/#coverage',
  },
  {
    title: 'О компании',
    link: 'about',
  },
  {
    title: 'Вакансии',
    link: 'vacancies',
  },
];



export default NAV_ITEMS